import React, { Fragment, useState } from "react";
import Markdown from "../../features/markdown";
import Spinner from "../../utils/spinner";

function BandcampPlayer({ album, path, title }) {
  const [loaded, setLoaded] = useState(false);
  let frameLoad = () => {
    setLoaded(true);
  };
  const spinnerClassName = loaded ? "invisible d-none" : "visible";
  const iframeClassName = loaded ? "visible" : "invisible";

  // eslint-disable-next-line max-len
  const src = `https://bandcamp.com/EmbeddedPlayer/album=${album}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/`;

  return (
    <Fragment>
      <Spinner className={spinnerClassName} />
      <iframe
        style={{ border: "0", width: "350px", height: "470px" }}
        src={src}
        className={iframeClassName}
        onLoad={frameLoad}
        seamless
      >
        <a href={`https://illposed.bandcamp.com/album/${path}`}>{title}</a>
      </iframe>
    </Fragment>
  );
}

function Music() {
  const spinner = <Spinner />;
  return (
    <>
      <div className="d-md-flex">
        <div className="pe-md-3">
          <h1>Music</h1>
        </div>
        <div className="pt-md-5">
          <Markdown source="pages/music.md" spinner={spinner} />
        </div>
      </div>
      <BandcampPlayer album="3868884272" path="prana" title="Prana" />{" "}
      <BandcampPlayer
        album="1911211917"
        path="compositions-2007-2010"
        title="Compositions 2007 – 2010"
      />{" "}
      &nbsp;
      <BandcampPlayer
        album="1013391997"
        path="tudes-du-piano"
        title="Études du Piano"
      />
    </>
  );
}

export default Music;
